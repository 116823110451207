// Dependent colors
$black: #000000;
$white: #ffffff;

$classic-base-color: #282A37;       //#282c37
$classic-primary-color: #A29BC8;    //#9baec8
$classic-secondary-color: #e0d9e8;  //#d9e1e8
$classic-highlight-color: #AC73DB;  //#2b90d9

// Differences
$success-green: lighten(#3c754d, 8%);

$base-overlay-background: $white !default;
$valid-value-color: $success-green !default;

$ui-base-color: $classic-secondary-color !default;
$ui-base-lighter-color: #BAB0CF;  //#b0c0cf
$ui-primary-color: #E99BED;       //#9bcbed
$ui-secondary-color: $classic-base-color !default;
$ui-highlight-color: $classic-highlight-color !default;

$primary-text-color: $black !default;
$darker-text-color: $classic-base-color !default;
$dark-text-color: #A176A7;      //#444b5d
$action-button-color: #A972A5;  //#606984

$inverted-text-color: $black !default;
$lighter-text-color: $classic-base-color !default;
$light-text-color: $dark-text-color !default;

//Newly added colors
$account-background-color: $white !default;

//Invert darkened and lightened colors
@function darken($color, $amount) {
  @return hsl(hue($color), saturation($color), lightness($color) + $amount);
}

@function lighten($color, $amount) {
  @return hsl(hue($color), saturation($color), lightness($color) - $amount);
}

$emojis-requiring-inversion: 'chains';
